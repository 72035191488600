import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Vue2TouchEvents from 'vue2-touch-events'

import App from './App.vue'
import storeOptions from './store'
import routes from './router'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Vue2TouchEvents)

const store = new Vuex.Store(storeOptions)

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
  })

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
