import API from '../../API'
import CART_ACTION from './cart.actions';
import FILTER_ACTIONS from "./filter.actions";
import PROMO_ACTIONS from "./promo.actions";

const actions = {
  getCatalogue: async ({ commit }) => {
    commit('toggleLoading', {loading: true})
    const catalogue = await API.getCatalogue();
    commit('toggleLoading', {loading: false})
    commit('setFilteredCatalogue', catalogue)
    commit('setCatalogue', catalogue);
  },
  toggleDelivery: ({ commit }, payload) => {
    commit('toggleDelivery', payload);
  },
  setCurrentPrice: ({ commit }, payload) => {
    commit('setCurrentPrice', payload);
  },
  setCurrentPacking: ({ commit }, payload) => {
    commit('setCurrentPacking', payload);
  },
  setClient: ({ commit }, payload) => {
    commit('setClient', payload);
  },
  toggleHamburger: ({ commit }) => {
    commit('toggleHamburger');
  },
  changeCountry: ({ commit }, payload) => {
    commit('changeCountry', payload)
  },
  async getStickers({ commit }) {
    const stickers = await API.getStickers();
    commit('setStickers', stickers)
  },
  setIsClientExist({ commit }, isExist) {
    commit('setIsClientExist', isExist);
  },
  async fetchNotification({ commit }) {
    let { enabled, hash, body } = (await API.getUpperNotification())[0];
    const currentHash = localStorage.getItem('upperNotificationHash');
    let isUnderstoodClicked = localStorage.getItem('upperNotificationIsUnderstood');
    if (currentHash !== hash || isUnderstoodClicked === null) {
      isUnderstoodClicked = 'false';
      localStorage.setItem('upperNotificationIsUnderstood', JSON.stringify(false));
    }

    commit('setNotification', { isEnabled: enabled && isUnderstoodClicked === 'false', hash, text: body})
  },
  setNotification({ commit }, payload) {
    commit('setNotification', payload);
  },
  ...CART_ACTION,
  ...FILTER_ACTIONS,
  ...PROMO_ACTIONS
}

export default actions;
