const CART_ACTIONS = {
  updateCart({ commit }, payload) {
    commit('updateCart', payload)
  },
  addToCart({ commit }, payload) {
    commit('addToCart', payload.product);
  },
  deleteFromCart({ commit }, payload) {
    commit('deleteFromCart', payload)
  },
  handleCartState: ({ getters, dispatch }, payload) => {
    const inCartProduct = getters.isInCart(payload.product);
    if (inCartProduct) {
      // console.log('UPDATING')
      dispatch('updateCart', payload);
    } else {
      // console.log('ADDING')
      dispatch('addToCart', payload);
    }
  },
  setCart: ({ commit }, payload) => {
    commit('setCart', payload);
  },
};

export default CART_ACTIONS;
