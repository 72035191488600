import API from '@/API'

const FILTER_ACTIONS = {
  setFilteredCatalogue({ getters, commit }, payload) {
    /**
     * При добавлении нового фильтра проходится по уже
     * отфильтрованному каталогу.
     *
     * В случае удаления одного из фильтров заново фильтрует
     * неотфильтрованный каталог.
     */
    let filteredCatalogue = [];
    if (payload.clicked) {
      filteredCatalogue = getters.filterCatalogueByEffectIds
    } else if (payload.clicked === false) {
      filteredCatalogue = getters.filterFilteredCatalogueByEffectId(payload.effectId)
    }

    commit('setFilteredCatalogue', filteredCatalogue)
  },
  handleFilters({ commit }, payload) {
    if (payload.clicked) {
      commit('deleteFilter', payload.effectId)
    } else {
      commit('addFilter', payload.effectId)
    }
  },
  nullifyFilters({ commit }) {
    commit('nullifyFilters')
  },
  async setEffects({ commit }) {
    commit('setEffects', await API.getEffects());
  }
};

export default FILTER_ACTIONS;
