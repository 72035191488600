const mutations = {
  setCatalogue: (state, payload) => {
    state.catalogue = [...payload];
  },
  toggleLoading: (state, payload) => {
    state.loading = payload.loading;
  },
  toggleMode: (state) => {
    state.isLight = !state.isLight;
  },
  changeMode: (state, payload) => {
    state.isLight = payload.isLight;
  },
  addToCart: (state, product) => {
    let localCart = JSON.parse(localStorage.getItem('cart'));
    state.cart = [...localCart, product];
  },
  updateCart: (state, payload) => {
    let localCart = JSON.parse(localStorage.getItem('cart'));
    state.cart = localCart.map(stateProduct => {
      if (stateProduct.id === payload.product.id && stateProduct.packing === payload.product.packing) {
        return {
          ...stateProduct,
          ...payload.product
        };
      }
      return stateProduct;
    })
  },
  deleteFromCart: (state, payload) => {
    let localCart = JSON.parse(localStorage.getItem('cart'));
    state.cart = localCart.filter(stateProduct => {
      return stateProduct.id !== payload.product.id || stateProduct.packing !== payload.product.packing;
    })
  },
  setCart: (state, payload) => {
    state.cart = [...payload.cart];
  },
  toggleDelivery: (state, payload) => {
    state.delivery = payload.delivery;
  },
  setCurrentPrice: (state, payload) => {
    state.currentPrice = payload.currentPrice;
  },
  setCurrentPacking: (state, payload) => {
    state.currentPacking = payload.currentPacking;
  },
  setClient: (state, payload) => {
    state.client = payload;
  },
  toggleHamburger: (state) => {
    state.showHamburger = !state.showHamburger;
  },
  changeCountry: (state, payload) => {
    state.country = payload.country;
  },
  setFilteredCatalogue: (state, filteredCatalogue) => {
    state.filteredCatalogue = [...filteredCatalogue]
  },
  addFilter: (state, effectId) => {
    // console.log('Add filter');
    state.filters = [...state.filters, effectId];
  },
  deleteFilter: (state, effectId) => {
    // console.log('Delete filter');
    state.filters = state.filters.filter(filter => filter !== effectId)
  },
  nullifyFilters: state => {
    state.filters = [];
  },
  setStickers: (state, stickers) => {
    state.stickers = stickers;
  },
  setPromo: (state, payload) => {
    state.promo = { ...state.promo, ...payload };
  },
  setEffects(state, effects) {
    state.effects = effects;
  },
  setIsClientExist(state, isExist) {
    state.isClientExist = isExist;
  },
  setNotification(state, payload) {
    state.notification = {...state, ...payload};
  }
}

export default mutations;
