import urls from './urls'
import { changeArrToMap } from '../helpers';

async function getCatalogue() {

  try {
    const response = await fetch(urls.catalogue);
    return await response.json();
    // console.log(json);
  } catch (e) {
    return null;
  }
}

async function sendNotification(id) {

  const response = await fetch(urls.notification(id));
  const json = await response.json();
  // console.log(json);
  return json.resultCode ? null : 'Everything is good';
}

async function getPacking(id) {

  
  try {
    const response = await fetch(urls.packing(id));
    return await response.json();
    // console.log(json)
  } catch (e) {
    return null;
  }
}

async function getClientByEmail(email) {

  const response = await fetch(urls.clientByEmail(email));
  return await response.json();
}

async function createClient(client, csrftoken) {

  const response = await fetch(urls.client, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken
    },
    body: JSON.stringify(client)
  });


  const json = await response.json();
  
  return json.resultCode ? null : json;
}


async function createOrderList(orderList, orderId) {

  
  for (let product of orderList) {

    const response = await fetch(urls.orderList, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order: orderId,
        pack: product.pack,
        price: product.price,
        volume: product.quantity
      })
    });

  
    const json = await response.json();
    
    if (json.resultCode) return null;
  }
  
  sendNotification(orderId);
  return 'Created Order List'
}

async function createOrder(order) {

  const response = await fetch(urls.order, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(order)
  });
  
  const json = await response.json();
  
  return json.resultCode ? null : json;
}

async function getFAQ() {
  const response = await fetch(urls.faq);
  const json = await response.json()

  return json.resultCode ? null : json;
}

async function getReviews() {
  const response = await fetch(urls.reviews);
  const json = await response.json()

  return json.resultCode ? null : json;
}

// async function getLocation(position) {
//   const { latitude, longitude } = position.coords;
//   const response = await fetch(urls.geolocation(latitude, longitude));
//   console.log(response)
// }

async function getEffects() {
  const response = await fetch(urls.effects);
  return await response.json()
}

async function getStickers() {
  const response = await fetch(urls.stickers);
  const json = await response.json();

  return changeArrToMap(json);
}

async function updateClient(client) {
  const response = await fetch(urls.update_client(client.id), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(client)
  });


  const json = await response.json();

  return json.resultCode ? null : json;
}

async function getUpperNotification() {
  const response = await fetch(urls.upperNotification);
  return response.json();
}

export default {getCatalogue, getPacking, createClient, 
                createOrder, createOrderList, getClientByEmail,
                getFAQ, getReviews, getEffects, getStickers,
                updateClient, getUpperNotification};
