const base = 'https://themantra.shop/api/';

const catalogue = base + 'catalog/?ordering=-available,weight';
const client = base + 'client/';
const update_client = (id) => base + `client/${id}/`;
const order = base + 'order/';
const orderList = base + 'order_list/';
const clientByEmail = email =>  base + `client/?email=${email}`;
const packing = id => base + `pack/?product=${id}&ordering=gram,capsule&available=true`;
const notification = id => base + `order/${id}/send/`; 
const faq = base + 'faq/';
const reviews = base + 'comment_all/';
const effects = base + 'tag/'
const stickers = base + 'sticker/'
const upperNotification = base + 'notification/'

export default { catalogue, client, order, orderList, clientByEmail, packing, 
                 notification, faq, reviews, effects, stickers, update_client,
                 upperNotification};
