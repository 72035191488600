const getters = {
  getProductById: state => id => {
    return state.catalogue.find(product => product.id === id);
  },
  getProductBySlug: state => slug => {
    return state.catalogue.find(product => product.slug === slug);
  },
  getCartLength: state => state.cart.length,

  isInCart: () => product => {
    let localCart = JSON.parse(localStorage.getItem('cart'))
    return localCart.find(stateProduct => stateProduct.id === product.id && stateProduct.packing === product.packing)
  },
  getCatalogueLength: state => state.catalogue.length,

  filterCatalogueByEffectIds: state => {
    return state.catalogue.filter(product => {

      return state.filters.every(id => product.tags.includes(id))
    });
  },
  filterFilteredCatalogueByEffectId: state => id => {
    return state.filteredCatalogue.filter(product => product.tags.includes(id))
  }
}

export default getters;
