// import cabinet from './cabinet'
import PROMO_ROUTES from "./promo";

const routes = [
  // { path: '/login', 
  //   name: 'loginPage', 
  //   component: () => import('@/pages/Login')
  // },

  { 
    path: '/', 
    component: () => import('@/pages/Main'),
    // component: () => import('../components/LoginPage'),
    children: [
      {
        path: '',
        name: 'catalogue', 
        component: () => import('@/pages/Main/Catalogue'),
      },
      {
        path: 'product-page/:slug',
        component: () => import('@/pages/Main/Product'),
        name: 'product',
      },
      {
        path: 'cart',
        component: () => import('@/pages/Main/Cart'),
        name: 'cart'
      },
      {
        path: 'reviews',
        component: () => import('@/pages/Main/Reviews'),
        name: 'reviews'
      },
      {
        path: 'contacts',
        component: () => import('@/pages/Main/Contacts'),
        name: 'contacts'
      },
      {
        path: 'gratitude',
        component: () => import('@/pages/Main/Gratitude'),
        name: 'gratitude',
        props: route => ({
            orderId: parseInt(route.params.orderId),
            total: parseInt(route.params.total)
        })
      },
      {
        path: 'offer',
        component: () => import('@/pages/Main/Offer'),
        name: 'offer'
      },
      {
        path: 'faq',
        component: () => import('@/pages/Main/FAQ'),
        name: 'faq'
      },
      {path: '/luchshie-nootropics', redirect: { name: 'catalogue' }},
      // cabinet
      {
        path: '/404',
        name: '404',
        component: () => import('@/pages/Main/NotFound')
      },
      ...PROMO_ROUTES,
    ],
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export default routes;
