const PROMO_ACTIONS = {
  /**
   *
   * @param {function}       commit
   * @param {Object}         payload
   * @param {number}         payload.id,
   * @param {string}         payload.title,
   * @param {string}         payload.code,
   * @param {number}         payload.percent,
   * @param {boolean | null} payload.fixed,
   * @param {boolean}        payload.is_active,
   * @param {boolean}        payload.is_percent,
   * @param {date}           payload.start,
   * @param {date}           payload.stop
   * @param {number}         payload.activeId
   * @returns {void}
   */
  setPromo({ commit }, payload) {
    commit('setPromo', {
      isPercent: payload.is_percent,
      value: payload.percent,
      title: payload.title,
      code: payload.code,
      id: payload.id,
      activeId: payload.activeId
    });
  }
};

export default PROMO_ACTIONS;
