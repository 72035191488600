const PROMO_ROUTES =  [
  {
    path: 'promo/:code/:clientId',
    component: () => import('@/pages/Promo/ActivatePromo'),
    name: 'activate-promo',
  },
  {
    path: 'unsubscribe/:id',
    component: () => import('@/pages/Promo/Unsubscribe'),
    name: 'unsubscribe-promo',
    beforeEnter: (to, from, next) => {
      if (!from.name) next();
      else next({ name: '404'});
    }
  }
]

export default PROMO_ROUTES;
