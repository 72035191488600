const state = {
  catalogue: [],
  filteredCatalogue: [],
  filters: [],
  effects: [],
  cart: [],
  stickers: null,
  loading: false,
  delivery: 'russian',
  currentPrice: {},
  currentPacking: {},
  client: {
    email: '',
    name: '',
    surname: '',
    country: '',
    city: '',
    index: '',
    phone: '',
    id: null
  },
  showHamburger: false,
  country: '',
  promo: {
    id: null,
    title: '',
    code: '',
    isPercent: false,
    value: 0,
    activeId: null,
  },
  isClientExist: false,
  notification: {
    text: '',
    isEnabled: false,
    hash: '',
    isUnderstood: false,
  }

};

export default state;
