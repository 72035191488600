function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getMinPackingsPrice(packings) {

  let minPacking = packings.reduce((prevPacking, currentPacking) => {

    let currentPrice = currentPacking.gram_price || currentPacking.capsule_price;
    let prevPrice = prevPacking.gram_price || prevPacking.capsule_price;
    return prevPrice > currentPrice ? currentPacking : prevPacking;
  }, packings[0])

  return minPacking.gram_price || minPacking.capsule_price;
}

export function changeArrToMap(array) {
  let map = new Map();

  array.forEach((item) => {
    map.set(item.id, item);
  })

  return map;
}

export default { getCookie, getMinPackingsPrice };
