<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'App',
  computed: mapState(['isLight', 'cart', 'showHamburger']),
  methods: mapActions(['setCart']),
  watch: {
    cart: function() {
        localStorage.setItem('cart', JSON.stringify([...this.cart]));
    },
    '$route': function() {
      this.setCart({cart: JSON.parse(localStorage.getItem('cart')) || []});
    }
  },
  created() {
    this.setCart({cart: JSON.parse(localStorage.getItem('cart')) || []});
  },
}
</script>
